var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-list"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "d-block"
  }, [_c('div', {
    staticClass: "d-flex align-center justify-space-between"
  }, [_c('div', [_vm._v(" Search & Filter ")]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-select', {
    staticClass: "mr-2",
    attrs: {
      "label": "Filter Distributor",
      "items": _vm.distributorOptions,
      "item-text": "title",
      "item-value": "value",
      "clearable": ""
    },
    model: {
      value: _vm.distributorFilter,
      callback: function callback($$v) {
        _vm.distributorFilter = $$v;
      },
      expression: "distributorFilter"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        _vm.aside = 'exporter';
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiDownload))]), _c('span', [_vm._v("Export")])], 1)], 1)])]), _vm.aside === 'exporter' ? _c('model-exporter-aside', {
    attrs: {
      "active": true,
      "resource": _vm.getQuery(),
      "export-resource": _vm.exportProducts,
      "enabled-plugins": false
    },
    on: {
      "change": function change($event) {
        _vm.aisde = false;
      },
      "aside": function aside(bol) {
        if (!bol) {
          _vm.aside = '';
        }
      }
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(" Export Products ")])], 2) : _vm._e(), _c('v-row', {
    staticClass: "px-2 ma-0"
  }, [_c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "Search",
      "outlined": "",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.tableColumns,
      "items": _vm.productListTable,
      "options": _vm.options,
      "server-items-length": _vm.totalListTable,
      "loading": _vm.loading,
      "show-select": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.media",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('main-media', {
          attrs: {
            "media": item.media
          }
        })];
      }
    }, {
      key: "item.product_number",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticStyle: {
            "min-width": "120px"
          }
        }, [_vm._v(" " + _vm._s(item.product_number) + " "), item.merchant_product_id ? _c('small', [_vm._v("(" + _vm._s(item.merchant_product_id) + ") ")]) : _vm._e()])];
      }
    }, {
      key: "item.product",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('a', {
          staticClass: "text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
        }, [_c('nature-icon', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.natureFilter,
            expression: "!natureFilter"
          }],
          staticClass: "mr-2",
          attrs: {
            "nature": item.nature
          }
        }), _vm._v(" " + _vm._s(_vm.t(item.title)) + " ")], 1)])];
      }
    }, {
      key: "item.max_sku_price",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex align-center",
          staticStyle: {
            "min-width": "200px"
          }
        }, [_vm._v(" " + _vm._s(_vm._f("price")(item.reference_price.min_sku_price)) + " - " + _vm._s(_vm._f("price")(item.reference_price.max_sku_price)) + " ")])];
      }
    }, {
      key: "item.category",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return _vm._l(item.categories, function (cat) {
          return _c('v-chip', {
            key: cat.id
          }, [_vm._v(" " + _vm._s(_vm.t(cat.name)) + " ")]);
        });
      }
    }, {
      key: "item.merchant",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('v-avatar', {
          class: 'v-avatar-light-bg primary--text',
          attrs: {
            "color": 'primary',
            "size": "32"
          }
        }), _c('div', {
          staticClass: "d-flex flex-column ms-3"
        }, [_c('span', {
          staticClass: "text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
        })])], 1)];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-chip', {
          staticClass: "v-chip-light-bg font-weight-semibold text-capitalize",
          class: "".concat(_vm.resolveStatusVariant(item.status), "--text"),
          attrs: {
            "small": "",
            "color": _vm.resolveStatusVariant(item.status)
          }
        }, [_vm._v(" " + _vm._s(item.status) + " ")]), item.status !== 'unpublished' ? _c('v-chip', {
          staticClass: "v-chip-light-bg font-weight-semibold text-capitalize",
          class: "".concat(item.active && item.status === 'published' ? 'success' : '', "--text"),
          attrs: {
            "small": "",
            "color": item.active ? 'success' : ''
          }
        }, [_vm._v(" " + _vm._s(item.active ? 'Active' : 'Inactive') + " ")]) : _vm._e()];
      }
    }, {
      key: "item.tag",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return _vm._l(item.tags, function (tag) {
          return _c('v-chip', {
            key: tag.id
          }, [_vm._v(" " + _vm._s(_vm.t(tag.name)) + " ")]);
        });
      }
    }, {
      key: "item.updated_at",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', {
          staticStyle: {
            "min-width": "100px"
          }
        }, [_c('span', [_vm._v(_vm._s(_vm._f("date")(item.updated_at)))])])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return undefined;
      }
    }], null, true),
    model: {
      value: _vm.selectedRows,
      callback: function callback($$v) {
        _vm.selectedRows = $$v;
      },
      expression: "selectedRows"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }